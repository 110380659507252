@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1 {
		@apply text-2xl md:text-4xl;
	}

	h2 {
		@apply text-xl md:text-3xl;
	}

	h3 {
		@apply text-lg md:text-2xl;
	}

	h4 {
		@apply text-base md:text-lg;
	}

	body {
		text-rendering: optimizeSpeed;
		@apply text-sm md:text-base font-sans bg-theme-background text-theme;
	}

	button,
	button:focus,
	button:hover {
		outline: none;
	}
}

@layer utilities {
	.shadow-sidebar {
		box-shadow: 0 0 12px -4px rgba(0, 0, 0, 0.07) !important;
	}
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

* {
	&::-webkit-scrollbar {
		@apply h-2 w-2;
	}

	&::-webkit-scrollbar-thumb {
		@apply bg-gray-300 dark:bg-neutral-700;
	}
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


html {
	overflow-x: hidden;
}

[mountpoint] {
	@apply flex min-h-screen;
}

.prevent-scroll {
	overflow-y: scroll;
	position: fixed;
	width: 100%;

	& body {
		padding-right: 0 !important;
		overflow: visible !important;
	}
}
