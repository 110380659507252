// For higher specificity
[mountpoint] {
	table.cfdump_struct {
		width: 100%;
	}

	table[class*='cfdump_'] {
		margin-top: 0 !important;

		td {
			@apply bg-theme border-b border-theme-divider;
			display: table-cell !important;
		}

		td.struct, td.cfc {
			@apply dark:bg-neutral-900 border-r border-b border-theme-divider;
		}

		th.array {
			@apply dark:bg-green-900;
		}

		td.array {
			@apply dark:bg-green-800;
		}

		th.cfc {
			@apply dark:bg-red-600;
		}
	}
}
