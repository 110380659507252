.MuiDivider-root,
.MuiDataGrid-root {
	@apply border-theme-divider;
}

.MuiButtonBase-root {
	padding: none;
}

*[class*="Mui"] {
	font-family: inherit;
}

.MuiPaper-root {
	border-radius: 0;
}

.MuiDataGrid-root {
	border: none;
}

.MuiInputBase-root {
	border-radius: 0;
}

.MuiDataGrid-columnHeaderTitleContainer {
	padding-left: 0 !important;
}

.MuiDialog-container {
	display: grid;
	place-items: center;
	overflow-y: scroll;

	.MuiPaper-root {
		overflow-y: unset;
		max-width: unset;
		@apply mx-5;
	}

	.MuiDialogTitle-root {
		@apply text-theme;
	}

	.MuiDialogContent-root {
		@apply overflow-y-visible;
	}

	.MuiDialogActions-root {
		@apply bg-gray-50 dark:bg-neutral-800;
	}
}